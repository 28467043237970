import React from 'react'
import get from 'lodash/get'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { SmartLink, formatAffiliateLink } from '../utils'

export const InstaWrapper = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`

export const Em = styled.div`
  text-align: center;
`

export const CommissionText = () => null

export const InstaText = styled.div`
  text-align: center;
  color: rgba(${ props => props.theme.black }, 0.75);
  font-family: ${ props => props.theme.bodyFont };
  font-weight: 300;
  font-size: 0.8rem;
  text-transform: uppercase;
  padding-bottom: 20px;
`

export const InstaGallery = styled.div`
  margin: 0 auto;
`

const ToggleGroup = styled.div`
  position: relative;

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: sticky;
    background-color: #fff;
    top: ${ props => props.theme.navHeight };
  }
`

const ToggleLink = styled(props => <Link {...props} />)`
  border-bottom: rgba(${ props => props.theme.black }, 0) solid 2px;
  color: ${ props => props.theme.black };
  font-family: ${ props => props.theme.bodyFont };
  font-size: 1.1rem;
  margin: 0 5px;
  margin: 10px 0;
  padding: 0 10px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.25s;
  width: 150px;
  text-align: center;

  &.active {
    color: ${ props => props.theme.black };
    border-bottom: ${ props => props.theme.black } solid 2px;
    transition: all 0.25s;
  }
`

export const InstaToggle = props => (
  <ToggleGroup>
    <div>
      <SmartLink component={ToggleLink} to={'/instagram/'}>
        Shop my Feed
      </SmartLink>
      <SmartLink component={ToggleLink} to={'/instagram/stories/'}>
        Shop my Stories
      </SmartLink>
    </div>
  </ToggleGroup>
)

const InstaRow = styled.div`
  display: flex;
  flex-direction: row;

  a {
    padding: 0 10px 10px 0;
    ${ props => props.theme.media.phone`

      padding: 0 3px 3px 0;
    ` }

    &:first-child {
      padding-left: 10px;

      ${ props => props.theme.media.phone`
        padding-left: 3px;
      ` }
    }

    &:last-child {
      padding-right: 10px;

      ${ props => props.theme.media.phone`
        padding-right: 3px;
      ` }
    }
  }
`

const InstaPost = styled(props => <Link {...props} />)`
  width: 33%;
  display: block;

`

const InstaLink = styled.a`
  width: 33%;
  display: block;

`

const InstaImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 2px;
  border: 0;
  display: block;
`

const renderPost = ({ node }, index) => {
  let url = '/'
  const item = get(node, 'items[0]', null)
  const type = get(item, '__typename', null)
  if (!node.image) {
    return
  }
  if (type === 'ContentfulCollection') {
    url = `/collection/${ item.slug }/`
  } else if (type === 'ContentfulProduct') {
    url = `/product/${ item.slug }/`
  } else if (node.url) {
    url = formatAffiliateLink(node.url)
  }
  if (url.slice(0, 4) === 'http') {
    return (
      <InstaLink key={index} href={url}>
        <InstaImage src={node.image.fixed.src} />
      </InstaLink>
    )
  } else {
    return (
      <InstaPost key={index} to={url}>
        <InstaImage src={node.image.fixed.src} />
      </InstaPost>
    )
  }
}

export const InstaPosts = ({ posts }) => {
  const copy = posts.slice(0).filter(post => post.node.image)
  const v = []
  while (copy.length) {
    const p = copy.splice(0, 3)
    v.push(
      <InstaRow key={v.length}>
        { p.map((node, index) => renderPost(node, index)) }
      </InstaRow>
    )
  }
  return v
}
