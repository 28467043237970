import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import { Seo } from '../components/seo'

import {
  CommissionText,
  InstaWrapper,
  InstaGallery,
  InstaPosts
} from '../components/instagram'

class TiktokVideos extends React.Component {
  render () {
    const posts = get(this.props, 'data.allContentfulTikTokVideos.edges', [])
    const title = 'TikTok Videos'
    return (
      <div>
        <Seo title={title} location={this.props.location} />
        <InstaWrapper>
          <CommissionText />
          <InstaGallery>
            <InstaPosts stories={true} posts={posts} />
          </InstaGallery>
        </InstaWrapper>
      </div>
    )
  }
}

export default TiktokVideos

export const pageQuery = graphql`
  query TiktokVideosQuery {
    allContentfulTikTokVideos(
      sort: {fields:[publishedDate], order:[DESC]}
    ) {
      edges {
        node {
          id
          url
          items {
            slug
            __typename
          }
          image {
            fixed(width:400) {
              width
              height
              src
            }
          }
        }
      }
    }
  }
`
